<template>
  <b-field label="Company Details">
    <div class="has-margin-top-50">
      <div v-if="company">
        <p v-if="$_.has(company, 'name')">
          <strong>{{ company.name }}</strong>
        </p>
        <p v-if="$_.has(company, 'vatNumber')">
          {{ company.vatNumber }}
        </p>
        <p v-if="$_.has(company, 'address.line1')">
          {{ company.address.line1 }}
        </p>
        <p v-if="$_.has(company, 'address.line2')">
          {{ company.address.line2 }}
        </p>
        <p v-if="$_.has(company, 'address.city')">
          {{ company.address.city }}
        </p>
        <p v-if="$_.has(company, 'address.postcode')">
          {{ company.address.postcode }}
        </p>
        <p v-if="$_.has(company, 'address.country.name')">
          {{ company.address.country.name }}
        </p>
        <button
          class="button has-margin-top-50"
          @click="openCompanyDetailsModal"
        >
          Edit
        </button>
      </div>
      <b-message v-else type="is-dark">
        <p>
          If {{ self ? "you're" : "this user is" }} a registered business you
          can enter {{ self ? "your" : "their" }} company details by
          <a @click="openCompanyDetailsModal">clicking here</a>.
        </p>
      </b-message>
    </div>
  </b-field>
</template>

<script>
export default {
  name: "CompanyDetailsField",
  props: {
    userId: {
      type: String,
      required: true
    }
  },
  computed: {
    user() {
      return this.$store.getters["user/user"](this.userId);
    },
    company() {
      return this.$_.get(this.user, "company", null);
    },
    self() {
      return this.$store.getters["auth/isCurrentUser"](this.userId);
    }
  },
  methods: {
    openCompanyDetailsModal() {
      let props = { userId: this.userId };
      if (!this.$_.isEmpty(this.user.company)) {
        props.default = this.user.company;
      }
      this.$modal.open({
        component: () => import("@shared/account/_companyDetailsModal"),
        parent: this,
        width: 560,
        hasModalCard: true,
        canCancel: [],
        props
      });
    }
  }
};
</script>
